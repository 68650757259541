<template>
  <div class="profile-form-wizard counselor-form">
    <br />
    <b-card>
      <b-row>
        <b-col cols="3" class="d-flex align-items-center justify-content-center">
          <div class="w-100">
            <b-progress :value="studentData.profile_completed" max="100" />
          </div>
        </b-col>
        <b-col cols="1" class="d-flex align-items-center">
          <h4 class="primary-color font-weight-bold mb-0">
            {{ parseInt(studentData.profile_completed) ? parseInt(studentData.profile_completed) : 0 }}%
          </h4>
        </b-col>
        <b-col v-if="user_type && user_type === 'counselor'" cols="2"
          class="d-flex align-items-end justify-content-center">
          <!-- <h5 class="text-capitalize mr-2 mb-0">{{ user_type }}</h5> -->
          <b-button variant="outline-warning" v-if="!studentData.is_converted || studentData.is_converted === 'N'"
            @click="setStudentConverted('Y')">
            Set As Converted
          </b-button>
          <b-button variant="outline-warning" v-else-if="
            studentData.is_converted && studentData.is_converted === 'Y'
          " disabled>
            Converted
          </b-button>
        </b-col>
        <b-col cols="2" v-if="user_type && user_type === 'counselor'"
          style="display: flex; align-items: center; justify-content: end"
          class="d-flex align-items-start justify-content-end flex-column">
          <span style="margin-right: 10px" class="mb-1">Application Limit</span>
          <b-form-input v-model="application_limit" type="number" @input="saveProfileExtraInfo" />
          <!-- <b-button @click="saveApplicationLimit">SAVE</b-button> -->
        </b-col>
        <b-col v-if="user_type === 'counselor'" class="d-flex align-items-end" cols="4">
          <v-select placeholder="Lead Type" v-model="leadType" :options="leadTypeOptions" :clearable="true"
            item-text="label" class="mr-1" style="width: 35%" :reduce="(item) => item.value" @input="() => {
              handleLeadSourceOptions();
              saveProfileExtraInfo();
            }
              " />

          <v-select placeholder="Lead Source" v-model="leadSource" style="width: 65%"
            :disabled="leadType ? false : true" :options="leadSourceOptions" :clearable="true" item-text="label"
            :reduce="(item) => item.value" @input="saveProfileExtraInfo" />
        </b-col>
        <!-- </div> -->
      </b-row>
    </b-card>
    <validation-observer ref="studentDetails" tag="form">
      <b-row class="student-details-form">
        <b-col md="6">
          <b-card title="Personal Details">
            <b-media class="mb-2">
              <template #aside>
                <b-avatar ref="previewEl" :src="FILESURL + studentData.user_profile_image" size="90px" rounded />
                <b-media-body v-if="!disabled_input" class="mt-75 ml-75">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                    @click="$refs.refImageInputEl.$el.click()">
                    Upload
                  </b-button>
                  <b-form-file ref="refImageInputEl" accept=".jpg, .png, .gif" :hidden="true" plain
                    v-model="uploaded_image" @input="uploadStudentProfileImage" />
                  <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                </b-media-body>
              </template>
            </b-media>
            <b-list-group flush>
              <b-list-group-item class="d-flex">
                <div>
                  <span>First Name: </span>
                  <span v-if="disabled_input">{{
                    studentData.user_firstname
                  }}</span>
                  <!-- <b-form-group label="First Name" label-for="mc-first-name"> -->
                  <validation-provider #default="{ errors }" name="First Name" rules="required">
                    <b-form-input v-if="!disabled_input" maxlength="255" id="mc-first-name" placeholder="First Name"
                      :disabled="disabled_input" v-model="studentData.user_firstname" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <div>
                  <span>Last Name: </span>
                  <span v-if="disabled_input">{{
                    studentData.user_lastname
                  }}</span>
                  <validation-provider #default="{ errors }" name="Last Name" rules="required">
                    <b-form-input v-if="!disabled_input" maxlength="255" id="mc-last-name" placeholder="Last Name"
                      :disabled="disabled_input" v-model="studentData.user_lastname" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- </b-form-group> -->
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Date of Birth: </span>
                  <span v-if="disabled_input">{{
                    new Date(studentData.student_DOB).toLocaleDateString()
                  }}</span>
                  <!-- <b-form-group label="Date of birth" label-for="mc-dob"> -->
                  <validation-provider #default="{ errors }" name="Date Of Birth" rules="required">
                    <!-- <b-form-datepicker
                      id="mc-dob"
                      style="font-size: 90%"
                      v-model="studentData.student_DOB"
                      class="form-group"
                    ></b-form-datepicker> -->
                    <datepicker v-if="!disabled_input" id="mc-dob" style="font-size: 90%"
                      v-model="studentData.student_DOB" :disabled="disabled_input" bootstrap-styling />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- </b-form-group> -->
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Gender: </span>
                  <span v-if="disabled_input">{{
                    studentData.student_gender
                  }}</span>
                  <span v-else>
                    <b-col>
                      <b-form-radio v-model="studentData.student_gender" :disabled="disabled_input" plain
                        value="female">
                        Female
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio v-model="studentData.student_gender" :disabled="disabled_input" plain value="male">
                        Male
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio v-model="studentData.student_gender" :disabled="disabled_input" plain value="other">
                        Other
                      </b-form-radio>
                    </b-col>
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Nationality: </span>
                  <span v-if="disabled_input">{{
                    nationality_country_name
                  }}</span>
                  <span v-else>
                    <!-- <b-form-group
                      label="Nationality"
                      label-for="mc-nationality"
                    > -->
                    <validation-provider #default="{ errors }" name="Nationality" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input"
                        v-model="studentData.student_nationality">
                        <b-form-select-option v-for="(item, index) in countries_list" :key="index"
                          :value="item.isoCode">
                          {{ item.name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>

              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Mobile No: </span>
                  <span v-if="disabled_input">{{
                    studentData.user_mobile
                  }}</span>
                  <span v-if="!disabled_input">
                    <validation-provider #default="{ errors }" name="Mobile No" rules="required">
                      <vue-tel-input :value="studentData.user_mobile" @input="(e) => (studentData.user_mobile = e)"
                        class="py-25" id="mobile-no" v-on:country-changed="countryChanged" :dropdownOptions="{
                          showFlags: true,
                          showSearchBox: true,
                          showDialCodeInSelection: true,
                        }" :inputOptions="{
                          placeholder: 'Mobile No',
                          required: true,
                          showDialCode: false,
                        }" defaultCountry="IN"></vue-tel-input>

                      <!-- <b-form-input v-if="!disabled_input" maxlength="255" type="number" id="mobile-no"
                        :disabled="disabled_input" v-model="studentData.user_mobile" placeholder="Mobile No" /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </div> -->
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Email</span>
                  <span v-if="disabled_input">{{
                    studentData.user_email
                  }}</span>
                  <span>
                    <!-- <b-form-group label-for="mc-email" label="Email"> -->
                    <!-- <div class="form-label-group"> -->
                    <validation-provider #default="{ errors }" name="Email Address" rules="required">
                      <b-form-input v-if="!disabled_input" maxlength="255" id="mc-email" type="email"
                        placeholder="Email" :disabled="disabled_input" v-model="studentData.user_email" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </div> -->
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Martial Status</span>
                  <span v-if="disabled_input">{{
                    studentData.marital_status
                  }}</span>
                  <span v-else>
                    <!-- <b-form-group label="Marital Status"> -->
                    <!-- <b-row> -->
                    <b-col>
                      <b-form-radio v-model="studentData.marital_status" :disabled="disabled_input" plain
                        value="unmarried">
                        Unmarried
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio v-model="studentData.marital_status" :disabled="disabled_input" plain
                        value="married">
                        Married
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio v-model="studentData.marital_status" :disabled="disabled_input" plain
                        value="divorced">
                        Divorced
                      </b-form-radio>
                    </b-col>
                    <!-- </b-row> -->
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>

              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Passport No</span>
                  <span v-if="disabled_input">{{
                    studentData.student_passport_no
                  }}</span>
                  <span v-if="!disabled_input">
                    <!-- <b-form-group label="Passport No" label-for="passport-no"> -->
                    <validation-provider #default="{ errors }" name="Passport No." rules="required">
                      <b-form-input maxlength="255" id="previous-name" :disabled="disabled_input"
                        placeholder="Passport No" v-model="studentData.student_passport_no" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card title="Emergency Contacts">
            <b-list-group flush>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Name </span>
                  <span>
                    <span v-if="disabled_input">{{
                      studentData.student_ec_name
                    }}</span>
                    <!-- <b-form-group label="Name" label-for="name"> -->
                    <!-- <div class="form-label-group"> -->
                    <span v-if="!disabled_input">
                      <validation-provider #default="{ errors }" name="Name" rules="required">
                        <b-form-input maxlength="255" id="name" placeholder="Name" :disabled="disabled_input"
                          v-model="studentData.student_ec_name" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <!-- </div> -->
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Mobile No:</span>
                  <span v-if="disabled_input">{{
                    studentData.student_ec_mobile
                  }}</span>
                  <span v-if="!disabled_input">
                    <validation-provider #default="{ errors }" name="Mobile Number" rules="required">
                      <b-form-input maxlength="255" type="number" id="mobile-no" :disabled="disabled_input"
                        v-model="studentData.student_ec_mobile" placeholder="Mobile" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Relation</span>
                  <span v-if="disabled_input">{{
                    studentData.student_ec_relation
                  }}</span>
                  <span v-if="!disabled_input">
                    <b-form-input maxlength="255" id="ec-relation" :disabled="disabled_input"
                      v-model="studentData.student_ec_relation" placeholder="Relation" />
                    <!-- </div> -->
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Email</span>
                  <span>
                    <span v-if="disabled_input">{{
                      studentData.student_ec_email
                    }}</span>
                    <!-- <b-form-group label-for="mc-email" label="Email"> -->
                    <!-- <div class="form-label-group"> -->
                    <span v-if="!disabled_input">
                      <validation-provider #default="{ errors }" name="Email" rules="required">
                        <b-form-input maxlength="255" id="mc-email" type="email" placeholder="Email"
                          :disabled="disabled_input" v-model="studentData.student_ec_email" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <!-- </div> -->
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card v-if="user_type === 'accounts'" title="University And Franchise Commissions">
            <b-list-group flush>
              <b-list-group-item class="d-flex">
                <div>
                  <span>University Commission</span>
                  <span>
                    <validation-provider #default="{ errors }" name="University Commission" rules="required">
                      <b-form-input type="number" id="university_commission" placeholder="University Commission"
                        v-model="studentData.university_commission" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <div>
                  <span>Franchise Commission</span>
                  <span>
                    <validation-provider #default="{ errors }" name="Franchise Commission" rules="required">
                      <b-form-input type="number" id="mobile-no" v-model="studentData.franchise_commission"
                        placeholder="Franchise Commission" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                </div>
              </b-list-group-item>
            </b-list-group>
            <div class="mt-1 ml-1">
              <b-button variant="primary" @click.prevent="saveUniAndFranchiseCom">Save</b-button>
            </div>
          </b-card>
          <b-card title="Application Documents Checklist">
            <div class="d-flex justify-content-between h-fit">
              <div class="w-50 h-fit">
                <b-form-checkbox :disabled="!['operations', 'counselor'].includes(user_type)"
                  v-model="studentData.show_app_checklist" name="check-button" switch>
                  Show
                </b-form-checkbox>
              </div>

              <!-- <div class="w-100 h-100">
                <v-select :disabled="!['operations', 'counselor'].includes(user_type)" placeholder="Country" multiple
                  v-model="studentData.app_country_ids" :options="$store.getters['home/getCountries']" :clearable="true"
                  label="country_name" class="mr-1" :reduce="(item) => item.country_id" />
              </div> -->
              <!-- {{ $store.getters['home/getUniversityList'] }} -->

              <div class="w-100 h-100">
                <v-select :disabled="!['operations', 'counselor'].includes(user_type)" placeholder="University" multiple
                  v-model="studentData.app_university_ids" :options="$store.getters['home/getUniversityList']"
                  :clearable="true" label="university_name" class="mr-1" :reduce="(item) => item.university_id" />
              </div>

              <div style="width: 400px">
                <v-select :disabled="!['operations', 'counselor'].includes(user_type)" placeholder="Course Level"
                  v-model="studentData.app_course_level_id" :options="$store.getters['home/getCourseLevels']"
                  :clearable="true" label="course_level_name" class="mr-1" :reduce="(item) => item.course_level_id" />
              </div>

              <div>
                <b-button v-if="['operations', 'counselor'].includes(user_type)" role="button" variant="primary"
                  size="md" @click="saveApplicationStudentProfileInfo">
                  SAVE
                </b-button>
              </div>
            </div>
          </b-card>
          <b-card title="Visa Documents Checklist">
            <div class="d-flex justify-content-between h-fit">
              <div class="w-100 h-fit">
                <b-form-checkbox :disabled="user_type != 'visa'" v-model="studentData.show_visa_checklist"
                  name="check-button" switch>
                  Show
                </b-form-checkbox>
              </div>

              <div v-if="studentData.show_visa_checklist" class="w-100 h-100">
                <v-select :disabled="user_type != 'visa'" placeholder="Visa Checklist Country"
                  v-model="studentData.visa_country_id" :options="$store.getters['home/getCountries']" :clearable="true"
                  label="country_name" class="mr-1" :reduce="(item) => item.country_id" />
              </div>
              <div>
                <b-button v-if="user_type === 'visa'" role="button" variant="primary" size="md"
                  @click="saveVisaStudentProfileInfo">
                  SAVE
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card title="Address">
            <b-list-group flush>
              <b-list-group-item class="d-flex">
                <div>
                  <span>Address </span>
                  <span v-if="disabled_input">{{
                    studentData.student_address
                  }}</span>
                  <span v-if="!disabled_input">
                    <validation-provider #default="{ errors }" name="Address" rules="required">
                      <b-form-input maxlength="255" id="-address" :disabled="disabled_input"
                        v-model="studentData.student_address" placeholder=" Address" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                  <!-- </b-form-group> -->
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Country</span>
                  <span v-if="disabled_input">{{ country_name }}</span>
                  <span v-else>
                    <!-- <b-form-group label="Country" label-for="mc-country"> -->
                    <validation-provider #default="{ errors }" name="Country" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input" v-model="studentData.student_country"
                        @change="onSelectCountry">
                        <b-form-select-option v-for="(item, index) in countries_list" :key="index"
                          :value="item.isoCode">
                          {{ item.name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>State</span>
                  <span v-if="disabled_input">{{ state_name }}</span>
                  <span v-else>
                    <!-- <b-form-group label="State" label-for="mc-state"> -->
                    <validation-provider #default="{ errors }" name="State" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input" v-model="studentData.student_state"
                        @change="onSelectState">
                        <b-form-select-option v-for="(item, index) in states_list" :key="index" :value="item.isoCode">
                          {{ item.name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>City</span>
                  <span v-if="disabled_input">{{
                    studentData.student_city
                  }}</span>
                  <span v-else>
                    <!-- <b-form-group label="City" label-for="mc-city"> -->
                    <validation-provider #default="{ errors }" name="City" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input" v-model="studentData.student_city">
                        <b-form-select-option v-for="(item, index) in cities_list" :key="index" :value="item.name">
                          {{ item.name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon icon="CheckIcon" size="16" class="text-success" />
                </span>
                <div>
                  <span>Pincode</span>
                  <span v-if="disabled_input">{{
                    studentData.student_pincode
                  }}</span>
                  <span v-else>
                    <!-- <b-form-group label="Pin code" label-for="pin-code"> -->
                    <!-- <div class="form-label-group"> -->
                    <validation-provider #default="{ errors }" name="pincode" rules="required">
                      <b-form-input maxlength="255" type="number" id="pin-code" :disabled="disabled_input"
                        v-model="studentData.student_pincode" placeholder="Pin code" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </div> -->
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card title="Preferences">
            <b-list-group flush>
              <!-- <b-list-group-item class="d-flex">
                <div>
                  <span>Preferred Country </span>
                  <span v-if="disabled_input">{{
                    $store.getters["home/getCountries"].length
                      ? $store.getters["home/getCountries"].find(
                        (country) =>
                          country.country_id === studentData.pref_country_id
                      )
                        ? $store.getters["home/getCountries"].find(
                          (country) =>
                            country.country_id === studentData.pref_country_id
                        ).country_name
                        : null
                      : null
                  }}</span>
                  <span v-else>
                    <validation-provider #default="{ errors }" name="Prefered Country" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input"
                        v-model="studentData.pref_country_id">
                        <b-form-select-option v-for="(item, index) in $store.getters[
                          'home/getCountries'
                        ]" :key="'p_country_' + index" :value="item.country_id">
                          <span style="font-weight: bold">{{
                            item.country_name
                            }}</span>
                        </b-form-select-option>
                      </b-form-select>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </span>
                </div>
              </b-list-group-item> -->
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Preferred Course Level </span>
                  <span v-if="disabled_input">{{
                    $store.getters["home/getCourseLevels"].length
                      ? $store.getters["home/getCourseLevels"].find(
                        (course) =>
                          course.course_level_id ===
                          studentData.pref_course_level_id
                      )
                        ? $store.getters["home/getCourseLevels"].find(
                          (course) =>
                            course.course_level_id ===
                            studentData.pref_course_level_id
                        ).course_level_name
                        : null
                      : null
                  }}</span>
                  <span v-else>
                    <validation-provider #default="{ errors }" name="Prefered Course Level" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input"
                        v-model="studentData.pref_course_level_id" @input="onSelectCourseLevel">
                        <b-form-select-option v-for="(item, index) in $store.getters[
                          'home/getCourseLevels'
                        ]" :key="'p_level_' + index" :value="item.course_level_id">
                          {{ item.course_level_name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span>Preferred Discipline </span>
                  <span v-if="disabled_input">
                    {{
                      $store.getters["home/getCourseCategories"].length > 0
                        ? $store.getters["home/getCourseCategories"].find(
                          (category) =>
                            category.course_category_id ===
                            studentData.pref_course_category_id
                        )
                          ? $store.getters["home/getCourseCategories"].find(
                            (category) =>
                              category.course_category_id ===
                              studentData.pref_course_category_id
                          ).course_category_name
                          : null
                        : null
                    }}
                  </span>
                  <span v-else>
                    <validation-provider #default="{ errors }" name="Prefered Discipline" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input"
                        v-model="studentData.pref_course_category_id">
                        <b-form-select-option v-for="(item, index) in $store.getters[
                          'home/getCourseCategories'
                        ]" :key="'p_category_' + index" :value="item.course_category_id">
                          {{ item.course_category_name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span> Preferred Intake Month </span>
                  <span v-if="disabled_input">{{
                    studentData.pref_intake_month
                  }}</span>
                  <span v-else>
                    <!-- <b-form-group label="Preferred Intake Month"> -->
                    <validation-provider #default="{ errors }" name="Prefered Intake Month" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input"
                        v-model="studentData.pref_intake_month">
                        <b-form-select-option v-for="(month, index) in all_months" :key="'p_intake_month_' + index"
                          :value="month">
                          {{ month }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span> Preferred Intake Year </span>
                  <span v-if="disabled_input">{{
                    studentData.pref_intake_year
                  }}</span>

                  <span v-else>
                    <validation-provider #default="{ errors }" name="Prefered Intake Year" rules="required">
                      <b-form-select class="form-group" :disabled="disabled_input"
                        v-model="studentData.pref_intake_year">
                        <b-form-select-option v-for="(year, index) in all_years" :key="'p_intake_year_' + index"
                          :value="year">
                          {{ year }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </b-form-group> -->
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex">
                <!-- <span class="mr-1">
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="text-success"
                  />
                </span> -->
                <div>
                  <span> Tuition Budget - per annum </span>
                  <span v-if="disabled_input">
                    {{
                      budgets && budgets.length
                        ? budgets.find(
                          (budget) =>
                            budget.value === studentData.tuition_budget
                        )
                          ? budgets.find(
                            (budget) =>
                              budget.value === studentData.tuition_budget
                          ).label
                          : null
                        : null
                    }}
                  </span>
                  <span v-else>
                    <b-form-select class="form-group" :disabled="disabled_input" v-model="studentData.tuition_budget">
                      <b-form-select-option v-for="(item, index) in budgets" :key="'budget_' + index"
                        :value="item.value">
                        {{ item.label }}
                      </b-form-select-option>
                    </b-form-select>
                  </span>
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider> -->
                  <!-- </b-form-group> -->
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card title="Required Documents">
            <b-list-group flush>
              <div v-for="(item, index) in studentData.required_docs" :key="'required_docs ' + index">
                <b-list-group-item class="d-flex" v-if="item.is_mandatory === 'Y'">
                  <div>
                    <span> {{ item.doc_name }} </span>
                    <span>
                      <feather-icon v-if="item.doc_url" icon="CheckCircleIcon" size="20" color="green" />
                      <feather-icon v-else icon="XCircleIcon" size="20" color="red" />
                    </span>
                    <span>
                      <b-form-file :id="'file-default_' + item.doc_id" :disabled="disabled_input" @input="(e) => {
                        docUpload(index, item, e);
                      }
                        " />
                    </span>
                  </div>
                </b-list-group-item>
              </div>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col md="6"> </b-col>
      </b-row>
      <b-button v-if="!disabled_input" role="button" size="md" @click="saveStudentProfile"
        style="background-color: var(--primary)">
        SAVE
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMedia,
  BAvatar,
  BListGroup,
  BListGroupItem,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormSelect,
  BFormRadioGroup,
  BFormSelectOption,
  BFormFile,
  BFormRadio,
  BFormTags,
  BFormTag,
  BFormCheckbox,
  BMediaBody,
  BCardText,
  BProgress,
} from "bootstrap-vue";
import Datepicker from "vuejs-datepicker";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { all_months, budgets } from "@/assets/json_data/data";
import CommonServices from "@/apiServices/CommonServices";
import { BASEURL, FILESURL } from "@/config";
import CounselorServices from "@/apiServices/CounselorServices";
import { Country, State, City } from "country-state-city";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import { TokenService } from "@/apiServices/storageService";
import axios from "axios";
import AgentServices from "@/apiServices/AgentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import OperationsServices from "@/apiServices/OperationsServices";
import AccountsServices from "@/apiServices/AccountsServices";

export default {
  components: {
    BRow,
    vSelect,
    BCol,
    BCard,
    BMedia,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormSelect,
    BFormRadioGroup,
    BFormSelectOption,
    BFormFile,
    BFormRadio,
    BFormTags,
    BFormTag,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BMediaBody,
    BCardText,
    BProgress,
    Datepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      leadType: null,
      leadSource: null,
      leadTypeOptions: [
        { label: "Direct", value: "direct" },
        { label: "Associate", value: "agent" },
        { label: "Franchise", value: "franchise" },
      ],
      leadSourceOptions: [],
      student_user_id: null,
      application_limit: 0,
      FILESURL,
      nationality_country_name: null,
      country_name: null,
      state_name: null,
      all_months,
      all_years: [],
      budgets,
      countries_list: [],
      states_list: [],
      cities_list: [],
      required,
      email,
      uploaded_image: null,
      studentData: {
        // Personal Information
        user_firstname: null,
        user_lastname: null,
        user_mobile: null,
        // user_country_code: null,
        user_email: null,
        marital_status: null,
        student_gender: null,
        student_DOB: null,
        student_nationality: null,
        student_passport_no: null,
        student_passport_applied: null,

        // Address
        student_address: null,
        student_country: null,
        student_state: null,
        student_city: null,
        student_pincode: null,

        // Emergency Contact
        student_ec_name: null,
        student_ec_mobile: null,
        student_ec_relation: null,
        student_ec_email: null,

        // Student Preferences
        pref_country_id: null,
        pref_course_level_id: null,
        pref_course_category_id: null,
        pref_intake_month: null,
        pref_intake_year: null,
        tuition_budget: null,

        required_docs: [],
        student_consent: "N",

        show_visa_checklist: "N",
        visa_country_id: null,

        // app_country_ids: [],
        app_university_ids: [],
        app_course_level_id: null,

        university_commission: null,
        franchise_commission: null,
      },
      present_commission: {
        university_commission: null,
        franchise_commission: null,
      },
      assigned_staff: [],

      applicationLimitDebounce: null,
    };
  },
  computed: {
    disabled_input() {
      let disable = true;
      if (
        ["agent", "franchise", "student_support"].includes(
          store.getters["user/getUserDetails"].user_type
        )
      ) {
        return false;
      }
      return disable;
    },
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let type = store.getters["user/getUserDetails"].user_type;

        if (type === "counselor") {
          this.handleLeadSourceOptions();
        }

        return type;
      } else {
        return null;
      }
    },
  },
  methods: {
    async saveUniAndFranchiseCom() {
      const obj = {
        university_commission: this.studentData.university_commission,
        franchise_commission: this.studentData.franchise_commission,
        student_user_id: this.student_user_id,
      };
      const res = await AccountsServices.saveCommissionInStudent(obj);
      if (res.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Success",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
      // console.log(res.data.status){
      //   this.present_commission.university_commission = obj.
      // }
    },

    async handleLeadSourceOptions() {
      this.leadSource = null;
      if (!this.leadType) {
        this.leadSource = null;
      }
      if (this.leadType === "direct") {
        this.leadSourceOptions = [
          {
            label: "Facebook",
            value: "facebook",
          },
          {
            label: "Instagram",
            value: "instagram",
          },
          {
            label: "Switz Website",
            value: "SwitzWebsite",
          },
          {
            label: "Alumni",
            value: "alumni",
          },
        ];
      }
      if (this.leadType === "agent") {
        const res = await CounselorServices.getAssignedAgents({
          leadUserType: this.leadType,
        });
        let assignedAgents = res.data.data;
        this.leadSourceOptions = [];
        for (let assignedAgent of assignedAgents) {
          this.leadSourceOptions.push({
            label:
              assignedAgent.user_firstname + " " + assignedAgent.user_lastname,
            value: assignedAgent.user_id,
          });
        }
      }
      if (this.leadType === "franchise") {
        const res = await CounselorServices.getAssignedAgents({
          leadUserType: this.leadType,
        });
        let assignedAgents = res.data.data;
        this.leadSourceOptions = [];
        for (let assignedAgent of assignedAgents) {
          this.leadSourceOptions.push({
            label:
              assignedAgent.user_firstname + " " + assignedAgent.user_lastname,
            value: assignedAgent.user_id,
          });
        }
      }
    },
    countryChanged(input) {
      if (this.studentData.user_mobile.split(" ").length > 1) {
        this.studentData.user_mobile =
          "+" + input.dialCode + " " + this.studentData.user_mobile;
      } else {
        this.studentData.user_mobile = "+" + input.dialCode;
      }
    },
    async saveApplicationStudentProfileInfo() {
      try {
        const res = await CommonServices.saveChecklistlistInfo({
          student_user_id: this.student_user_id,
          app_university_ids: this.studentData.app_university_ids.toString(),
          // app_country_ids: this.studentData.app_country_ids.toString(),
          app_course_level_id: this.studentData.app_course_level_id,
          show_app_checklist: this.studentData.show_app_checklist,
        });

        if (!res.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Student Profile Not Saved",
              icon: "X",
              variant: "failure",
            },
          });
          return;
        }

        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: res.data.message || "Student Profile Saved",
            icon: "EditIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.error(`Error in saveApplicationStudentProfileInfo `, error);
      }
    },

    async saveVisaStudentProfileInfo() {
      const res = await OperationsServices.saveVisaChecklistInfo({
        student_user_id: this.student_user_id,
        visa_country_id: this.studentData.visa_country_id,
        show_visa_checklist: this.studentData.show_visa_checklist,
      });

      if (!res.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: res.data.message || "Student Profile Not Saved",
            icon: "X",
            variant: "failure",
          },
        });
        return;
      }

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: res.data.message || "Student Profile Saved",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    async setStudentConverted(convert_status) {
      try {
        const response = await CounselorServices.setStudentConverted({
          student_user_id: this.student_user_id,
          convert_status,
        });
        if (response.data.status) {
          this.getStudentProfile(this.student_user_id);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Converted Successfully",
              icon: "Check",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Not Converted",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Error in Setting as converted",
            icon: "X",
            variant: "failure",
          },
        });
        console.error("Error in setting student converted  ", err);
      }
    },
    async getStudentRequiredDocs(student_user_id, course_level_id) {
      try {
        const response = await CommonServices.getStudentRequiredDocs({
          student_user_id,
          course_level_id,
        });
        if (response.data.status) {
          this.studentData.required_docs = response.data.data;
        }
      } catch (err) {
        console.error("Error in getting student required documents ", err);
      }
    },
    getNumberArray(list) {
      if (!list) return [];

      const stringList = list.split(",");
      let finalList = [];
      for (let item of stringList) {
        finalList.push(parseInt(item));
      }

      return finalList;
    },
    async getStudentProfile(student_user_id) {
      if (!student_user_id) return;
      try {
        const response = await CommonServices.getStudentDetails({
          student_user_id,
        });

        if (response.data.status) {
          this.studentData = {
            ...this.studentData,
            ...response.data.data,
            // app_country_ids: this.getNumberArray(
            //   response.data.data.app_country_id
            // ),
            app_course_level_id: response.data.data.app_course_level_id,
            app_university_ids: this.getNumberArray(
              response.data.data.app_university_id
            ),
          };
          this.application_limit = this.studentData.application_limit;

          if (this.studentData.student_country) {
            this.onSelectCountry(this.studentData.student_country);
          }
          if (this.studentData.student_state) {
            this.onSelectState(this.studentData.student_state);
          }
          if (this.studentData.pref_course_level_id) {
            let course_level_id = this.studentData.pref_course_level_id;
            this.getStudentRequiredDocs(student_user_id, course_level_id);
          }
          if (this.studentData.student_nationality) {
            this.nationality_country_name = Country.getCountryByCode(
              this.studentData.student_nationality
            ).name;
          }
          if (this.studentData.student_country) {
            this.country_name = Country.getCountryByCode(
              this.studentData.student_country
            ).name;
            if (this.studentData.student_state) {
              this.state_name = State.getStateByCodeAndCountry(
                this.studentData.student_state,
                this.studentData.student_country
              ).name;
            }
          }
          if (this.studentData.source_type) {
            this.leadType = this.studentData.source_type;
            // this.handleLeadSourceOptions();
          }
          if (this.studentData.source) {
            this.leadSource = this.studentData.source;
          }
          if (this.studentData.source_user_id) {
            this.leadSource = this.studentData.source_user_id;
          }

          if (this.studentData.show_visa_checklist) {
            let show_visa = this.studentData.show_visa_checklist;
            this.studentData.show_visa_checklist =
              show_visa === "Y" ? true : false;
          }
          if (this.studentData.show_app_checklist) {
            let show_app = this.studentData.show_app_checklist;
            this.studentData.show_app_checklist =
              show_app === "Y" ? true : false;
          }
        }
      } catch (error) {
        console.error("Error in getting Student Profile ", error);
      }
    },
    validateStudentProfileForm() {
      return new Promise((resolve, reject) => {
        this.$refs.studentDetails.validate().then((success) => {
          if (success) {
            this.saveStudentProfile();
            resolve(true);
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Fill all neccessary feilds",
                icon: "EditIcon",
                variant: "error",
              },
            });
            reject();
          }
        });
      });
    },
    async saveStudentProfile() {
      if (this.invalidContactInformation()) return;

      try {
        const resp = await AgentServices.saveStudentProfile({
          student_user_id: this.student_user_id,
          ...this.studentData,
        });
        if (resp.data.status) {
          this.getStudentProfile(this.student_user_id);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: resp.data.message || "Student Profile Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: resp.data.message || "User Profile Not Saved",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Error in saving student Profile",
            icon: "X",
            variant: "failure",
          },
        });
        console.error("Error saving student profile ", err);
      }
    },
    addCommas(amt) {
      if (amt) return amt.toLocaleString("en-IN");
      return null;
    },
    onSelectCourseLevel(course_level_id) {
      this.getStudentRequiredDocs(this.student_user_id, course_level_id);
    },
    getCountries() {
      this.countries_list = Country.getAllCountries();
      // this.states_list = State.getAllStates();
      // this.cities_list = City.getAllCities();
    },
    onSelectCountry(country_code) {
      this.states_list = State.getStatesOfCountry(country_code);
      this.cities_list = City.getCitiesOfCountry(country_code);
    },
    onSelectState(state_code) {
      this.cities_list = City.getCitiesOfState(
        this.studentData.student_country,
        state_code
      );
    },
    async uploadStudentProfileImage() {
      try {
        const AUTH_TOKEN = TokenService.getToken();
        const formData = new FormData();
        formData.append("image", this.uploaded_image);
        const response = await axios.post(
          `${BASEURL}/agent/upload-student-profile-image/${this.student_user_id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.studentData.user_profile_image = response.data.data;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Profile Image not Uploaded",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.error("Error uploading profile image ", err);
      }
    },
    async getAssignedStaffToStudent() {
      try {
        const response = await CommonServices.getAssignedStaffToStudent({
          student_user_id: this.student_user_id,
        });
        if (response.data.status) {
          this.assigned_staff = response.data.data;
        }
      } catch (err) {
        console.error("Error in getting assigned student to student ", err);
      }
    },
    setAllYears() {
      const currentYear = new Date().getFullYear();
      for (let i = 0; i < 10; i++) {
        this.all_years.push(currentYear + i);
      }
    },
    async docUpload(index, doc, file) {
      try {
        const format = /[^\x00-\x7F]/;
        if (format.test(file.name)) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Filename cannot contain special characters",
              icon: "XIcon",
              variant: "failure",
            },
          });
          return;
        }
        if (file.size > 10 * 1024 * 1024) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Filesize cannot be greater than 5MB",
              icon: "XIcon",
              variant: "failure",
            },
          });
          return;
        }

        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("doc", file);

        const response = await axios.post(
          `${BASEURL}/agent/upload-student-doc/${this.student_user_id}/${doc.doc_id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getStudentRequiredDocs(
            this.student_user_id,
            this.studentData.pref_course_level_id
          );
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Uploaded Failed",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.error("Error in Uploading Student Doc");
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Document Uploaded Failed",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },
    async saveProfileExtraInfo() {
      if (this.applicationLimitDebounce)
        clearTimeout(this.applicationLimitDebounce);

      this.applicationLimitDebounce = setTimeout(async () => {
        try {
          const response = await CounselorServices.saveProfileExtraInfo({
            application_limit: this.application_limit,
            student_user_id: this.studentData.user_id,
            source_type: this.leadType,
            source: this.leadType == "direct" ? this.leadSource : null,
            source_user_id: this.leadType != "direct" ? this.leadSource : null,
          });
          if (response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Profile Information Saved",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        } catch (err) {
          console.error("Error in saving application limit  ", err);
        }
      });
    },
    invalidContactInformation() {
      const email = this.studentData.user_email;
      const mobile = this.studentData.user_mobile;
      const emergencyEmail = this.studentData.student_ec_email;
      const emergencyMobile = this.studentData.student_ec_mobile;

      let emailsValid = false;
      let mobilesValid = false;

      if (!mobile || !emergencyMobile) {
        mobilesValid = true;
      } else {
        mobilesValid = !(mobile.replace(/[\s+]/g, "") == emergencyMobile);
      }

      if (!email || !emergencyEmail) {
        emailsValid = true;
      } else {
        emailsValid = !(email == emergencyEmail);
      }

      if (!emailsValid || !mobilesValid) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Emergency Contact Information Cannot Be Same.",
            icon: "EditIcon",
            variant: "error",
          },
        });
        return true;
      }
      return false;
    },
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.getStudentProfile(student_user_id);
      this.student_user_id = student_user_id;
    } else {
      console.error("Student User Id is is not a number ", student_user_id);
    }
    this.getCountries();
    this.setAllYears();
    this.$store.dispatch("home/onGetCountries");
    this.$store.dispatch("home/onGetCourseLevels");
    this.$store.dispatch("home/onGetCourseCategories");
    this.$store.dispatch("home/onGetUniversityList");

    console.log({ s: this.$store });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.student-details-form .list-group-item>span {
  display: none;
}

.counselor-form label {
  font-weight: bold;
  color: #000;
  font-size: 15px;
}

.counselor-form .list-group-item>div {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}

.counselor-form .list-group-item>div span:first-child {
  font-weight: bold;
  color: #000;
  font-size: 15px;
}
</style>
