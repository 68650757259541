<template>
  <div>
    <b-modal
      id="add-staff-visa-doc-modal"
      ref="visa-doc-staff-upload-modal"
      centered
      title="Upload Document"
      ok-title="Submit"
      @show="resetAddDocModal"
      @hidden="resetAddDocModal"
      @ok="documentUpload"
    >
      <br />
      <b-form-select class="form-group" v-model="doc_form.vdoc_id">
        <b-form-select-option
          v-for="(item, index) in $store.getters['home/getVisaDocumentTypes']"
          :key="index"
          :value="item.vdoc_id"
        >
          {{ item.vdoc_name }}
        </b-form-select-option>
      </b-form-select>
      <br />

      <v-select
        v-model="doc_form.application_id"
        :reduce="(item) => item.sa_mapper_id"
        :options="studentApplicationOptions"
        placeholder="Select Application"
        label="university_name"
      >
        <template #option="{ course_name, university_name }">
          <h6 class="ml-50 h5">{{ university_name }}</h6>
          <p class="ml-50">{{ course_name }}</p>
        </template>

        <template #selected-option="{ course_name, university_name }">
          <div class="d-flex flex-column">
            <h6 class="h5">{{ university_name }}</h6>
            <p class="">{{ course_name }}</p>
          </div>
        </template>
      </v-select>
      <br />
      <b-form-input v-model="doc_form.extra_text" placeholder="Comment" />
      <br />
      <b-form-file
        class="form-group"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        v-model="doc_form.doc_file"
      ></b-form-file>
      <br />
    </b-modal>
    <b-modal
      id="remove-staff-doc-modal"
      ref="visa-doc-staff-remove-modal"
      centered
      title="Remove Document"
      ok-title="Remove"
      @hidden="resetRemoveDoc"
      @ok="removeDoc"
    >
      {{ doc_to_remove ? doc_to_remove.vdoc_name : "" }}
    </b-modal>
    <b-card class="mb-0">
      <div class="">
        <b-row v-if="!['agent', 'franchise', 'counselor', 'student_support'].includes(user_type)">
          <b-col>
            <b-button
              v-b-modal.add-staff-visa-doc-modal
              variant="outline-primary"
              >Add Document</b-button
            >
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="pagination.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pagination.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="onSearch"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="studentStafVisaDocumentsTableId"
        ref="refstudentStaffVisaDocumentsTable"
        class="position-relative mt-2"
        :items="studentStaffDocsList"
        responsive
        :fields="studentStaffDocumentsColumns"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        primary-key="sd_id"
      >
        <template #cell(index)="data">
          {{ studentStaffDocsList.indexOf(data.item) + 1 }}
        </template>

        <template #cell(vdoc_name)="data">
          {{
            data.item.vdoc_name +
            (data.item.extra_text ? " - " + data.item.extra_text : "")
          }}
        </template>

        <template #cell(application)="data">
          <h6 class="h5">{{ data.item.university_name }}</h6>
          <p class="">{{ data.item.course_name }}</p>
        </template>

        <template #cell(open)="data">
          <b-button
            variant="outline-success"
            role="button"
            class="btn-icon rounded-circle"
            @click="openDoc(data.item)"
          >
            <!-- <b-avatar variant="light-danger" size="30"> -->
            <feather-icon icon="EyeIcon" size="15" class="text-success" />
            <!-- </b-avatar> -->
          </b-button>
        </template>

        <template #cell(uploaded_by)="data">
          {{ data.item.user_firstname + " " + data.item.user_lastname }}
        </template>

        <template #cell(remove)="data">
          <b-button
            v-if="!['agent', 'franchise', 'counselor', 'student_support'].includes(user_type)"
            @click="setDocToRemove(data.item)"
            class="btn-icon rounded-circle"
            :disabled="
              $store.getters['user/getUserDetails'].user_id !==
              data.item.staff_user_id
            "
            variant="outline-danger"
          >
            <feather-icon icon="TrashIcon" size="15" class="text-danger" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ studentStaffDocsList.length }} entries
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              aria-controls="studentStaffDocumentsTableId"
              v-model="pagination.currentPage"
              :total-rows="studentStaffDocsList.length"
              :per-page="pagination.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { BASEURL, FILESURL } from "@/config/index";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
// import OperationsServices from "@/apiServices/OperationsServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { TokenService } from "@/apiServices/storageService";
import axios from "axios";
import helpers from "@/libs/helpers";

export default {
  components: {
    // BAvatar,
    BButton,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
    user_id() {
      if (store.getters["user/getUserDetails"].user_id) {
        return store.getters["user/getUserDetails"].user_id;
      } else {
        return null;
      }
    },
  },
  data() {
    return {
      student_user_id: null,
      studentStaffDocsList: [],
      fullStudentStaffDocsList: [],
      studentApplicationOptions: [],
      studentStaffDocumentsColumns: [
        { key: "index", label: "#" },
        { key: "vdoc_name", label: "Type of document" },
        { key: "application", label: "Application" },
        { key: "open", label: "Preview" },
        { key: "uploaded_by", label: "Uploaded By" },
        {
          key: "created_at",
          label: "Uploaded Date",
          formatter: helpers.getDateTimeFormatted,
        },
        { key: "remove", label: "Remove" },
      ],
      doc_form: {
        vdoc_id: null,
        vdoc_file: null,
        extra_text: null,
        application_id: null,
      },
      doc_to_remove: null,
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
    };
  },
  methods: {
    resetAddDocModal() {
      this.doc_form.vdoc_id = null;
      this.doc_form.doc_file = null;
      this.doc_form.application_id = null;
    },
    async getStudentApplications(student_user_id) {
      try {
        const response = await CommonServices.getStudentApplications({
          student_user_id,
        });
        if (response.data.status) {
          const { applications } = response.data.data;
          this.studentApplicationOptions = applications;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log("Error in getting student applications ", error);
      }
    },
    async documentUpload(e) {
      e.preventDefault();

      if (!this.doc_form.vdoc_id) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Select Document Type",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        return;
      }
      if (!this.doc_form.doc_file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please Upload Document",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        return;
      }

      if (this.doc_form.doc_file.size > 10 * 1024 * 1024) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Filesize cannot be greater than 5MB",
            icon: "XIcon",
            variant: "failure",
          },
        });
        return;
      }

      const format = /[^\x00-\x7F]/;
      if (format.test(this.doc_form.doc_file.name)) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Filename cannot contain special characters",
            icon: "XIcon",
            variant: "failure",
          },
        });
        return;
      }

      try {
        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("doc", this.doc_form.doc_file);

        const extra_text = this.doc_form.extra_text;
        this.doc_form.extra_text = null;

        const application_id = this.doc_form.application_id;
        this.doc_form.application_id = null;

        const response = await axios.post(
          `${BASEURL}/common/upload-visa-doc-for-student/${
            this.doc_form.vdoc_id
          }/${this.student_user_id}?extra_text=${
            extra_text ? extra_text : ""
          }&application_id=${application_id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.getDocUploadedForStudent(this.student_user_id);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$nextTick(() => {
            this.$refs["visa-doc-staff-upload-modal"].toggle("#toggle-btn");
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Uploaded Failed",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error uploading file ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    setDocToRemove(doc) {
      this.doc_to_remove = doc;
      this.$refs["visa-doc-staff-remove-modal"].show();
    },
    resetRemoveDoc() {
      this.doc_to_remove = null;
    },
    async removeDoc() {
      try {
        const response = await CommonServices.removeVisaDocForStudent({
          student_user_id: this.student_user_id,
          map_id: this.doc_to_remove.svsd_id,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document removed",
              icon: "Check",
              variant: "Success",
            },
          });
          this.getDocUploadedForStudent(this.student_user_id);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Error in removing doc",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error in removing doc ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Error in removing doc",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },
    onSearch(text) {
      this.studentStaffDocsList = this.fullStudentStaffDocsList.filter(
        (doc, index) => {
          return doc.vdoc_name.toLowerCase().includes(text.toLowerCase());
        }
      );
    },
    openDoc(doc) {
      const url = FILESURL + doc.vdoc_url;
      window.open(url);
    },
    async getDocUploadedForStudent(student_user_id) {
      try {
        const response = await CommonServices.getVisaDocUploadedForStudent({
          student_user_id,
        });
        if (response.data.status) {
          this.studentStaffDocsList = response.data.data;
          this.fullStudentStaffDocsList = response.data.data;
        }
      } catch (error) {
        console.log("Error in getting Student Documents ", error);
      }
    },
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.getDocUploadedForStudent(student_user_id);
      this.getStudentApplications(student_user_id);
      this.student_user_id = student_user_id;
      this.$store.dispatch("home/onGetVisaDocumentTypes");
    } else {
      console.log("Student User Id is is not a number ", student_user_id);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
