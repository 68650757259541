<template>
  <div>
    <b-modal ref="taskChatModal" @hidden="onTaskModalClose" hide-footer>
      <div class="AliifChat-chatbox">
        <div class="chat-header" style="background-color: #5367ff">
          <h5 style="color: #fff" class="m-0">Switz</h5>
        </div>
        <div class="chat-messages">
          <b-row v-for="(c, index) in taskChat" :key="'tc_' + index">
            <div class="message ml-2 mb-2">
              <div class="user-info">
                <span class="user-name">
                  {{ `${c.user_firstname} ${c.user_lastname}` }}
                </span>
                <span class="user-type">
                  {{ c.user_type_tag || c.user_type_name }}
                </span>
              </div>
              <div class="message-text">
                <span class="message-content">{{ c.message }}</span>
                <sub class="message-timestamp">{{
                  moment(c.created_at).format("DD-MM-YYYY - hh:mm A")
                  }}</sub>
              </div>
            </div>
          </b-row>
        </div>
      </div>
    </b-modal>
    <br />
    <b-card class="chat-widget" no-body style="margin-left: 2%">
      <h3 class="my-2 ml-1"><b>Student Tasks</b></h3>
      <b-table :items="taskList" :fields="taskFields" style="height: 100%" responsive>
        <template #cell(show_details)="row">
          <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">
          </b-form-checkbox>
          <b-badge pill :variant="`light-danger`" class="text-capitalize"
            v-if="isOverdue(row.item.tk_status, row.item.deadline_date)">
            overdue
          </b-badge>
        </template>

        <template #row-details="row">
          <div class="pt-2" v-if="row.item.tk_description">
            <h4>Description</h4>
            <div v-html="row.item.tk_description"></div>
          </div>
        </template>

        <template #cell(start_date)="data">
          <div style="width: max-content">
            <p>
              {{ moment(data.item.start_date).format("DD MMM YYYY") }}
            </p>
          </div>
        </template>

        <template #cell(deadline_date)="data">
          <div style="width: max-content">
            <p>
              {{ moment(data.item.deadline_date).format("DD MMM YYYY") }}
            </p>
          </div>
        </template>

        <template #cell(chat)="data">
          <div style="width: max-content">
            <feather-icon style="cursor: pointer" icon="MessageSquareIcon" size="20"
              @click.prevent.stop="showTaskChatModal(data.item)" />
          </div>
        </template>

        <template #cell(tk_priority)="data">
          <div style="width: max-content">
            <b-badge pill :variant="`light-${priorityColorMapper[data.item.tk_priority]}`" class="text-capitalize">
              {{ data.item.tk_priority }}
            </b-badge>
          </div>
        </template>

        <template #cell(tk_application)="data">
          <div style="width: max-content">
            <h5>{{ data.item.university_name }}</h5>
            <p>
              {{ data.item.course_name }}
            </p>
          </div>
        </template>

        <template #cell(assignee_comments)="data">
          <div style="width: max-content">
            <p>{{ data.item.assignee_comments || "-" }}</p>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BInputGroup,
  BButton,
  BTable,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ChatLog from "./ChatLog.vue";
import OperationsServices from "@/apiServices/OperationsServices";
import CommonServices from "@/apiServices/CommonServices";
import { FILESURL } from "@/config";
import moment from "moment";

export default {
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    BTable,
    BBadge,
    ChatLog,
    VuePerfectScrollbar,
    BFormCheckbox,
  },
  data() {
    return {
      student_user_id: null,
      counselor: {},
      student: {},
      chat: [],
      FILESURL,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      taskList: [],
      taskFields: [
        // { key: "tk_id", label: "#", sortable: false },
        { key: "show_details", label: "Show", sortable: false },
        { key: "tk_status", label: "Progress", sortable: false },
        { key: "tk_title", label: "Subject", sortable: false },
        { key: "start_date", label: "Start Date", sortable: false },
        { key: "deadline_date", label: "Deadline", sortable: false },
        { key: "tk_priority", label: "Priority", sortable: false },
        { key: "tk_application", label: "Application", sortable: false },
        { key: "tk_application_id", label: "Application ID", sortable: false },
        { key: "assignee_comments", label: "Remarks", sortable: false },
        { key: "chat", label: "chat", sortable: false },
        { key: "assignor_user_name", label: "Assignor", sortable: false },
        { key: "assignee_user_name", label: "Assignee", sortable: false },
      ],
      priorityColorMapper: {
        high: "danger",
        medium: "primary",
        low: "success",
      },
      taskChat: [],
    };
  },
  methods: {
    onTaskModalClose() {
      this.taskChat = [];
      this.$refs["taskChatModal"].hide();
    },
    async showTaskChatModal(tk) {
      try {
        this.$refs["taskChatModal"].show();

        const res = await CommonServices.getTaskChat({
          task_id: tk.tk_id,
        });

        this.taskChat = res.data.data;
      } catch (err) {
        console.error(`Error in showTaskChatModal `, err);
      }
    },
    async getStudentCounselorChat(student_user_id) {
      try {
        const response = await OperationsServices.getStudentCounselorChat({
          student_user_id,
        });
        if (response.data.status) {
          this.counselor = response.data.data.counselor;
          this.student = response.data.data.student;
          this.chat = response.data.data.chat;
        }
      } catch (error) {
        console.error("Error in getting Student Profile ", error);
      }
    },

    async getStudentTasks(student_user_id) {
      try {
        const response = await CommonServices.getAllTasks({
          student_user_id,
        });

        if (response.data.status) {
          this.taskList = response.data.data.data;
        }
      } catch (error) {
        console.error("Error in getting Student Profile ", error);
      }
    },

    isOverdue(tk_status, deadline_date) {
      const currentDate = moment();
      const deadlineDate = moment(deadline_date);
      if (tk_status != "complete") {
        if (currentDate.isAfter(deadlineDate)) {
          return true;
        }
      }
      return false;
    },

    moment,
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.student_user_id = student_user_id;
      this.getStudentTasks(student_user_id);
    } else {
      console.error("Student User Id is is not a number ", student_user_id);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
